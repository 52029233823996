import { mapRange, mapColorToValue, rgb2hex } from './utils';

window.addEventListener('load', () => {
  const sectionParent = document.querySelector('.leistung-sticky-section-parent');
  const sectionStickyContent = document.querySelector('.leistung-sticky-section-content');

  const header = document.querySelector('#header');

  sectionParent.style.height = `${sectionStickyContent.clientHeight * 4}px`;

  const slideInText = document.querySelector('.leistung-sticky-section-content__slide-in-text');
  const animatedLogoItem1 = document.querySelector('.rotated-animated-component-item-1');
  const animatedLogoItem2 = document.querySelector('.rotated-animated-component-item-2');
  const animatedLogoItem3 = document.querySelector('.rotated-animated-component-item-3');
  const animatedLogoItem4 = document.querySelector('.rotated-animated-component-item-4');

  const animatedLogoText = document.querySelectorAll('.rotated-animated-component-item__text');

  let logoItem1Bg = rgb2hex(
    getComputedStyle(animatedLogoItem1).getPropertyValue('background-color'),
  );
  const logoItem2Bg = rgb2hex(
    getComputedStyle(animatedLogoItem2).getPropertyValue('background-color'),
  );
  const logoItem3Bg = rgb2hex(
    getComputedStyle(animatedLogoItem3).getPropertyValue('background-color'),
  );
  const logoItem4Bg = rgb2hex(
    getComputedStyle(animatedLogoItem4).getPropertyValue('background-color'),
  );

  const sectionParentClientHeight = sectionParent.clientHeight;
  const sectionStickyContentClientHeight = sectionStickyContent.clientHeight;
  const headerClientHeight = header.clientHeight;

  const maxValue = Math.ceil(
    sectionParentClientHeight * 0.8 - sectionStickyContentClientHeight - headerClientHeight,
  );
  const minValue = 0;

  const slideTextMaxValue =
    sectionParentClientHeight * 0.85 - sectionStickyContentClientHeight - headerClientHeight;
  let angle = 0;

  document.addEventListener('scroll', () => {
    const mappedRotationAngle = Math.ceil(
      mapRange(sectionStickyContent.offsetTop, minValue, maxValue, 0, 180),
    );

    const mappedColorAndOpacity = mapRange(mappedRotationAngle, 45, 90, 0, 1);

    const mappedColorItem1 = mapColorToValue(mappedColorAndOpacity, logoItem1Bg, '#f72c00', 0);
    const mappedColorItem2 = mapColorToValue(mappedColorAndOpacity, logoItem2Bg, '#f72c00');
    const mappedColorItem3 = mapColorToValue(mappedColorAndOpacity, logoItem3Bg, '#f72c00');
    const mappedColorItem4 = mapColorToValue(mappedColorAndOpacity, logoItem4Bg, '#f72c00');

    if(mappedRotationAngle >= 90) {
      angle = 90;
    } else if (mappedRotationAngle == 0){
      angle = 0;
    } else {
      angle = mappedRotationAngle;
    }

    animatedLogoItem3.style.transform = `rotate3d(0, 0, 1, ${angle}deg)`;
    animatedLogoItem2.style.transform = `rotate3d(0, 0, 1, ${angle}deg)`;
    animatedLogoItem3.style['-webkit-transform'] = `rotate3d(0, 0, 1, ${angle}deg)`;
    animatedLogoItem2.style['-webkit-transform'] = `rotate3d(0, 0, 1, ${angle}deg)`;

    animatedLogoItem1.style.backgroundColor = mappedRotationAngle >= 90 ? '#f72c00' : mappedColorItem1;
    animatedLogoItem1.style.borderColor = '#f72c00';

    animatedLogoItem2.style.backgroundColor = mappedRotationAngle >= 90 ? '#f72c00' : mappedColorItem2;
    animatedLogoItem2.style.borderColor = mappedRotationAngle >= 90 ? '#f72c00' : mappedColorItem2;

    animatedLogoItem3.style.backgroundColor = mappedRotationAngle >= 90 ? '#f72c00' : mappedColorItem3;
    animatedLogoItem3.style.borderColor = mappedRotationAngle >= 90 ? '#f72c00' : mappedColorItem3;

    animatedLogoItem4.style.backgroundColor = mappedRotationAngle >= 90 ? '#f72c00' : mappedColorItem4;
    animatedLogoItem4.style.borderColor = mappedRotationAngle >= 90 ? '#f72c00' : mappedColorItem4;

    animatedLogoText.forEach((text) => (text.style.opacity = 1 - mappedColorAndOpacity));

    if (sectionStickyContent.offsetTop > slideTextMaxValue) {
      slideInText.classList.add('slide-in');
    } else {
      slideInText.classList.remove('slide-in');
    }
  });

  // Homepage - mobile
  const linkAnimatedLabel = document.querySelector('.sanders-hero-links__animated');

  if (linkAnimatedLabel &&  window.innerWidth <= 992) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('sanders-hero-links__animated--slide-in');
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    observer.observe(linkAnimatedLabel);
  }
});